import React, { useEffect, useState } from "react"
import { BackTop, Form, Tabs, PageHeader, Skeleton, Button, message } from "antd"
import { navigate } from "gatsby"
import QRCode from "qrcode.react"

import Layout from "../../layouts"


import "./id.css"
import "./basic.css"
import { getJobDetail } from "../../api/job"
import { USERID } from "../../utils/constant"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { TabPane } = Tabs

export default () => {
  const [jobData, setJobData] = useState({
    address: "",
    createTime: "",
    desc: "",
    education: "",
    id: "",
    isDelete: "",
    modifyTime: "",
    name: "",
    publisherID: "",
    publisherName: "",
    salary: ""
  })
  const [dataState, setDataState] = useState("loading")

  const imgList = [
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-31/1617188222810-image.png",
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-10/1615382619367-33333.jpg",
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-31/1617188265225-image.png",
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-31/1617188302986-image.png",
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-31/1617188338241-image.png",
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-31/1617188465835-image.png",
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-31/1617188480245-image.png",
    "https://gitee.com/guanpengchn/picture/raw/master/2021-3-31/1617188495258-image.png",
  ]
  useEffect(() => {
    initJobDetail()
  }, [])

  const handleAllNumber = () => {
    const btn = document.getElementById("mdnice-navbar-btn-login")
    btn.click();
  }

  const initJobDetail = async () => {
    const matchReg = /^\/jobs\/id\/(.+)/
    const match =
      typeof window !== "undefined" && window.location.pathname.match(matchReg)
    if (match) {
      const res = await getJobDetail(match[1])
      console.log(res)
      if (res && res.data.data) {
        setJobData(res.data.data)
        console.log(res.data.data)
        setDataState("loaded")
      } else {
        setDataState("error")
      }
    } else {
      setDataState("error")
    }
  }

  const renderArticle = (html, url, type) => {
    let res
    if (type === "mobile") {
      res = (
        <div className="color-preview">
          <div className="nice-wx-box">
            <section
              id="nice"
              className="preview-image-container"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          {url && (
            <div className="qrcode-container">
              <div className="qrcode">
                <QRCode value={url} />
              </div>
              <p className="qrcode-desc">扫码手机预览文章</p>
            </div>
          )}
        </div>
      )
    } else {
      res = (
        <div className="color-preview">
          <div className="nice-wx-box-pc">
            <section
              id="nice"
              className="preview-image-container"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      )
    }
    return res
  }
  const loading = dataState === "loading"

  return (
    <Layout
      isSpacerDisplay={false}
      title={jobData.name}
    >
      <div className="theme-detail-container">
        <div className="theme-detail-header">
          <PageHeader title="全部岗位" onBack={() => navigate("/jobs")} />
        </div>
        <BackTop />
        <div className="theme-detail-content">
          <section className="theme-profile-container">
            <div className="theme-profile">
              <div className="theme-cover">
                {!loading ? (
                  <img
                    src={imgList[jobData.mobile % 8]}
                    alt="theme-cover-img"
                    className="theme-cover-img"
                  />
                ) : (
                  <Skeleton.Avatar
                    style={{ height: 200, width: 250 }}
                    active
                    shape="square"
                  />
                )}
              </div>
              <div className="profile-content">
                <Skeleton loading={loading}>
                  <p className="theme-title">
                    {jobData.name}
                  </p>
                  <p className="theme-author">
                    <p>发布人：{jobData.publisherName}</p>
                    <p>发布日期：
                      {jobData.modifyTime && new Date(parseInt(jobData.modifyTime)).toISOString().replace("T", " ")}
                    </p>
                    <p>联系方式：{typeof window !== "undefined" && window.localStorage.getItem(USERID) ?
                      jobData.mobile :
                      <>{jobData.mobile ? jobData.mobile.slice(0, 7) + "****" : null} <Button onClick={handleAllNumber}>点击查看完整号码</Button></>}
                    </p>
                    <p>发布公司：{jobData.company}</p>
                  </p>
                  <p className="theme-description">{jobData.description}</p>
                </Skeleton>
              </div>
            </div>
          </section>
          <div className="available-color">
            <Tabs
              className="tabs"
              type="card"
            >
              <TabPane tab="工作详情" key="3">
                <div className="color-preview">
                  <Form
                    {...layout}
                    name="basic"
                  >
                    <Form.Item
                      label="岗位名称："
                    >
                      <p>{jobData.name}</p>
                    </Form.Item>
                    <Form.Item
                      label="岗位地址："
                    >
                      <p>{jobData.address}</p>
                    </Form.Item>
                    <Form.Item
                      label="学历要求："
                    >
                      <p>{jobData.education}</p>
                    </Form.Item>
                    <Form.Item
                      label="薪资范围："
                    >
                      <p>{jobData.salary}</p>
                    </Form.Item>
                    <Form.Item
                      label="岗位描述："
                    >
                      <p>{jobData.desc}</p>
                    </Form.Item>

                    <Form.Item
                      label="请联系："
                    >
                      <p>{jobData.publisherName}</p>
                    </Form.Item>
                  </Form>

                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  )
}
